import { httpCore } from "../../config/http";
import { versionVeiculo } from "../../config/Versions";
import { veiculo } from "../../config/Paths";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";

export const actionTypes = {
  INDEX: "VEHICLE_INDEX",
  SHOW: "VEHICLE_SHOW",
  DESTROY: "VEHICLE_DESTROY",
  CHANGE: "VEHICLE_CHANGE",
  SUCCESS: "VEHICLE_SUCCESS",
  ERROR: "VEHICLE_ERROR",
  SELECTED: "INDEX_SELECTED",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const indexSelected = (payLoad) => ({
  type: actionTypes.SELECTED,
  payLoad,
});

export const selecteds = (selecteds) => (dispatch) => {
  dispatch(indexSelected(selecteds));
};

export const index = () => (dispatch) => {
  return httpCore
    .get(versionVeiculo + veiculo)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200) {
          dispatch(indexResponse(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
      }
      console.log(error.config);
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao carregar dados de veículos. Tente recarregar a página.",
        }));
    });
};

//STORE
export const store = (vehicle) => (dispatch) => {
  console.log(vehicle);
  return httpCore
    .post(versionVeiculo + veiculo, {
      entidadeId: 0,
      descricao: vehicle["descricao"],
      placa: vehicle["placa"],
      marca: vehicle["marca"],
      modelo: vehicle["modelo"],
      cor: vehicle["cor"],
      ano: vehicle["ano"],
      capacidadeMaxKg: vehicle["capacidadeKg"],
      capacidadeMaxM3: vehicle["capacidadeM3"],
      quantidadeEixos: vehicle["quantidadeEixos"],
      quantidadeMaximaAtendimento: vehicle["quantidadeMaximaAtendimento"],
      ativo: vehicle["ativo"],
      proprio: vehicle["proprio"],
      tipoVeiculoId: vehicle["tipoVeiculoId"],
      motoristaPadraoId: vehicle["motoristaPadraoId"],
      caracteristicaTransporteId: 0,
    })
    .then((res) => {
      if (typeof res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: vehicle["descricao"] + " cadastrado com sucesso",
            })
          );
          dispatch(index());
        } else {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar tipo de Carroceria",
            })
          );
        }
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao salvar dados de veículo. Tente refazer a operação.",
        }));
    });
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
    })
  );
  return httpCore
    .put(versionVeiculo + veiculo + data.id, data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(index());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao salvar dados de veículo. Tente refazer a operação.",
        }));
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return httpCore
    .delete(versionVeiculo + veiculo + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Tipo de veículo não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
        dispatch(
          changeNotify({
              open: true,
              class: "error",
              msg: "Ocorreu um erro ao remover o veículo. Tente refazer a operação.",
          }));
      }
      // console.log(error.config);
    });
};
