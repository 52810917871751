import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React from "react";
import { Provider } from "react-redux";
import Notify from "./Components/Notify";
import LoadingIcon from "./Components/LoadingIcon";
import DefaultTheme from "./Themes/DefaultTheme";
import { ThemeProvider } from "@material-ui/core/styles";
import Rotas from "./Routes";
import { store } from "./store/store";

const App = () => (
    <Provider store={store}>
        <ThemeProvider theme={DefaultTheme}>
            <LoadingIcon />
            <Notify />
            <Rotas />
        </ThemeProvider>
    </Provider>
);

export default App;
