import { http } from "../../config/http";
import { changeNotify } from "./notify.action";
import { changeLoadingRoterization } from "./loadingRoterization.action";
import Assigned from "../../utils/Assigned";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const actionTypes = {
  INDEX: "ROMANEIO_INDEX",
  SHOW: "ROMANEIO_SHOW",
  DESTROY: "ROMANEIO_DESTROY",
  CHANGE: "ROMANEIO_CHANGE",
  SUCCESS: "ROMANEIO_SUCCESS",
  ERROR: "ROMANEIO_ERROR",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const indexRomaneios = () => (dispatch) => {
  const empresaId = cookies.get("empresaid");
  if (!Assigned(empresaId)) {
    empresaId = 0;
  }

  return http
    .get(
      "/v1/romaneio?statusRange=CarregadoOuAguardandoSaida&statusRange=EmTransitoParaEntrega&statusRange=RotaConcluidaOuAguardandoFechamentoFinanceiro&empresaid=" +
        empresaId
    )
    .then((res) => {
      //return http.get("/v1/romaneio?empresaid=" + empresaId).then(res => {
      const sortedRomaneioList = res.data.data.sort((a, b) => b.id - a.id);

      if (Assigned(res) && Assigned(res.data)) {
        dispatch(indexResponse(res.data.data));

        return sortedRomaneioList;
      }
    })
    .catch((error) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Ocorreu um erro ao carregar a lista de romaneios. Tente recarregar a página.",
        })
      );
    });
};

export const changeRomaneios = (id) => (dispatch) => {
  return http
    .get("/v1/romaneio/" + id + "?empresais=" + 1)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200) {
          dispatch(change(res.data.data));
          console.log(res.data);
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
      }
      console.log(error.config);
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao carregar dados de romaneio. Tente recarregar a tela.",
        }));
    });
};

export const storeRomaneio = (romaneios) => (dispatch) => {
  console.log(romaneios);
  dispatch(
    changeLoadingRoterization({
      open: true,
      msg: "Criando Romaneios do planejamento " + romaneios.solicitacaoId,
    })
  );
  return http
    .post("/v1/planejamento/conclusao", romaneios)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200 || res.request.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Romaneios criados com sucesso",
            })
          );
          dispatch(
            changeLoadingRoterization({
              open: false,
              msg: "",
            })
          );
          return res;
        }
      }
    })
    .catch((error) => {
      dispatch(
        changeLoadingRoterization({
          open: false,
          msg: "",
        })
      );

      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Houve um erro ao tentar gerar o romaneio",
        })
      );

      console.log(error);
      console.log("Error storeRomaneioPlanejamento ", error);
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao salvar dados de romaneios. Tente refazer a operação.",
        }));
    });
};

export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  return http
    .delete("/v1/romaneio/" + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(indexRomaneios());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Tipo de veículo não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(indexRomaneios());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
        dispatch(
          changeNotify({
              open: true,
              class: "error",
              msg: "Ocorreu um erro ao remover o romaneio. Tente refazer a operação.",
          }));
      }
      // console.log(error.config);
    });
};
