import { http } from "../../config/http";
import { versionPlanejamento } from "../../config/Versions";
import { Planejamento } from "../../config/Paths";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import { changeLoadingRoterization } from "./loadingRoterization.action";
import Cookies from "universal-cookie";
import Assigned from "../../utils/Assigned";

const cookies = new Cookies();
// import history from '../../history'

export const actionTypes = {
  INDEX: "PLANNING_INDEX",
  SHOW: "PLANNING_SHOW",
  DESTROY: "PLANNING_DESTROY",
  CLEAR: "PLANNING_CLEAR",
  CHANGE: "PLANNING_CHANGE",
  SUCCESS: "PLANNING_SUCCESS",
  ERROR: "PLANNING_ERROR",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

const planningData = {
  data: null,
  empresaid: cookies.get("empresaid", { path: "/" }),
};

export const index = () => async (dispatch) => {
  try {
    const res = await http.get(
      `/v1/Planejamento?possuiRomaneio=false&empresaid=${planningData.empresaid}`
    );
    if (res.status === 200) {
      if (JSON.stringify(planningData.data) !== JSON.stringify(res.data.data)) {
        planningData.data = res.data.data.sort((a, b) => b.id - a.id);
        dispatch(indexResponse(res.data.data));
      }
      return res.data.data;
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
    dispatch(
      changeNotify({
          open: true,
          class: "error",
          msg: "Ocorreu um erro ao carregar dados de planejamento. Tente recarregar a tela.",
      }));
  }
};

//STORE
export const store = (planning) => (dispatch) => {
  dispatch(
    changeLoadingRoterization({
      open: true,
      msg: "Criando Planejamento...",
    })
  );
  return http
    .post("/v1/Planejamento", {
      empresaId: planning["empresaId"],
      dataHoraRomaneio: planning["dataHoraRomaneio"],
      regrasLogisticas: planning["regrasLogisticas"],
      depositos: planning["depositos"],
      veiculos: planning["veiculos"],
      configuracoes: planning["configuracoes"],
      informacoesEntregas: planning["informacoesEntregas"],
    })
    .then((res) => {
      if (typeof res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          //   dispatch(
          //     changeLoadingRoterization({
          //       open: true,
          //       // class: "success",
          //       msg: "Redirecionando...",
          //     })
          //   );
          dispatch(
            changeLoadingRoterization({
              open: false,
              // class: "success",
              msg: "",
            })
          );

          return res.data.id;
        }
      }
    })
    .catch((error) => {
      console.log("RequestPlanning:\n", planning);

      if (Assigned(error) && Assigned(error.response)) {
        if (
          error.response.status === 404 ||
          error.response.status === 400 ||
          error.response.status === 500
        ) {
          dispatch(changeLoading({ open: false }));

          let errorMsg = "Ocorreu um erro no momento do planejamento";

          if (
            Assigned(error.response.data) &&
            Assigned(error.response.data.message) &&
            error.response.data.message.trim() !== ""
          ) {
            errorMsg = error.response.data.message;
          }

          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: errorMsg?.message || errorMsg,
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        // dispatch(index());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
        dispatch(
          changeNotify({
              open: true,
              class: "error",
              msg: "Ocorreu um erro ao salvar dados de planejamento. Tente refazer a operação.",
          }));
      }
      // console.log(error.config);
    });
};

// function send() {
//   let history = useHistory();

//   function handleClick() {
//     history.push("/home");
//   }

//   return (
//     <button type="button" onClick={handleClick}>
//       Go home
//     </button>
//   );
// }

// export const teste = () => (
//   history.pushLater({ pathname: "/home" })
// )

//verificando retorno
export const verify = (id) => (dispatch) => {
  dispatch(
    changeLoadingRoterization({
      open: true,
      msg: "Carregando parametros de Rota...",
    })
  );
  const timer = setInterval(() => {
    return http
      .get("/v1/Planejamento/" + id)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (res.status === 200 || res.status === 201) {
            if (res.data !== "undefined") {
              if (res.data.processado === false) {
                console.log("documento nao procesado");
                dispatch(
                  changeLoadingRoterization({
                    open: true,
                    // class: "success",
                    msg: "Processando dados...",
                  })
                );
              }
              if (res.data.processado === true) {
                dispatch(
                  changeLoadingRoterization({
                    open: true,
                    // class: "success",
                    msg: "Redirecionando...",
                  })
                );
                clearInterval(timer);
                // dispatch(teste)
              }
            }
            // dispatch(index());
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404 || error.response.status === 400) {
            dispatch(
              changeLoading({
                open: false,
              })
            );
            dispatch(
              changeNotify({
                open: true,
                class: "error",
                msg: "Ocorreu um erro no planejamento",
              })
            );
          }
        } else if (error.request) {
          //  A solicitação foi feita, mas nenhuma resposta foi recebida
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Sem resposta do servidor",
            })
          );
          // dispatch(index());
          console.log(error.request);
        } else {
          // Algo aconteceu na configuração da solicitação que acionou um erro
          console.log("Error", error.message);
          dispatch(
            changeNotify({
                open: true,
                class: "error",
                msg: "Ocorreu um erro ao carregar dados de planejamento. Tente refazer a operação.",
            }));
        }
        // console.log(error.config);
      });
  }, 5000);
  // dispatch(teste)
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Alterando...",
    })
  );
  return http
    .put("/v1/Planejamento/" + data.id, data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(index());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao salvar dados de planejamento. Tente refazer a operação.",
        }));
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const clearPlanning = () => ({
  type: actionTypes.CLEAR,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return http
    .delete("/v1/Planejamento/" + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Tipo de veículo não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
        dispatch(
          changeNotify({
              open: true,
              class: "error",
              msg: "Ocorreu um erro ao remover o planejamento. Tente refazer a operação.",
          }));
      }
      // console.log(error.config);
    });
};
