import { actionTypes } from "../Actions/deposito.action";

const initialState = {
  pcms: {
    data: [],
  },
  pcm: {},
  success: false,
  error: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {    ...state,
        pcms: {
          ...state.pcms,
          data: payLoad,
        },};
    case actionTypes.DESTROY:
      return {
        ...state,
        pcms: {
          ...state.pcms,
          data: state.pcms.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        pcm: {
          ...state.pcm,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
