import { httpCore } from "../../config/http";
import { versionFuncionario } from "../../config/Versions";
import { motorista } from "../../config/Paths";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import getErrorMessage from "../../Services/Errors/ErrorsServices";
import { IsTechUser } from "../../Services/Auth";

export const actionTypes = {
  INDEX: "MOTORISTA_INDEX",
  SHOW: "MOTORISTA_SHOW",
  DESTROY: "MOTORISTA_DESTROY",
  CHANGE: "MOTORISTA_CHANGE",
  SUCCESS: "MOTORISTA_SUCCESS",
  ERROR: "MOTORISTA_ERROR",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const getMotoristas = () => (dispatch) => {
  return httpCore
    .get(versionFuncionario + motorista)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200) {
          dispatch(indexResponse(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
      }
      console.log(error.config);
      dispatch(
        changeNotify({
            open: true,
            class: "error",
            msg: "Ocorreu um erro ao carregar lista de motoristas. Tente recarregar a página.",
        }));
    });
};

//STORE
export const store = (model) => (dispatch) => {
  return httpCore
    .post(versionFuncionario + motorista, model)
    .then((res) => {
      if (typeof res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: model.descricao + " cadastrado com sucesso",
            })
          );
          dispatch(getMotoristas());
        } else {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar tipo de Carroceria",
            })
          );
        }
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar dados de motorista. Tente refazer a operação",
        })
      );
    });
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
    })
  );
  return httpCore
    .put(versionFuncionario + motorista + data.id, data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(getMotoristas());
      }
    })
    .catch((erro) => {
      console.log(data);
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar dados de motorista. Tente refazer a operação",
        })
      );
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const deleteMotorista = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return httpCore
    .delete(versionFuncionario + motorista + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(getMotoristas());
      }
    })
    .catch((error) => {
       // Error
       if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 500) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro de Servidor",
            })
          );
        }
        if (error.response.status === 409) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: getErrorMessage(
                error.response.data.errorInfo.errorCode,
                error.response.data.errorInfo.detail,
                "/motorista",
                IsTechUser()
              ),
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(getMotoristas());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        // console.log("Error", error.message);
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao remover o motorista. Tente refazer a operação",
          })
        );
      }
      // console.log(error.config);
    });
};
