import { actionTypes } from "../Actions/motorista.action";

const initialState = {
  motoristas: {
    data: [],
  },
  driver: {},
  success: false,
  error: {},
};

// eslint-disable-next-line
export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {
        ...state,
        motoristas: {
          ...state.motoristas,
          data: payLoad,
        },
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        motoristas: {
          ...state.motoristas,
          data: state.motoristas.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
