import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import notifyReducer from "./notify.reducer";
import loadingiconReducer from "./loadingicon.reducer";
import loadingReducer from "./loading.reducer";
import motoristaEscalaReducer from "./motoristaEscala.reducer";
import motoristaReducer from "./motorista.reducer";
import bairroReducer from "./bairro.reducer";
import gerenciarEntregasReducer from "./gerenciarEntregas.reducer";
import veiculoReducer from "./veiculo.reducer";
import planningReducer from "./planning.reducer";
import loadingRoterizationReducer from "./loadingRoterization.reducer";
import depositoReducer from "./deposito.reducer";
import veiculoTipos from "./veiculoTipos.reducer";
import romaneioReducer from "./romaneio.reducer";
import tipoOcorrenciaReducer from "./tipoOcorrencia.reducer";
import tipoDespesaReducer from "./tipoDespesa.reducer";
import motivoPausaReducer from "./motivoPausa.reducer";
import motivoNaoEntregaReducer from "./motivoNaoEntrega.reducer";
import areaEntregaReducer from "./default.reducer";
import listaRomaneiosReducer from "./default.reducer";
import PreviewTextReducer from "./previewtext.reducer";

const rootReducer = combineReducers({
  authReducer,
  notifyReducer,
  loadingiconReducer,
  loadingReducer,
  motoristaEscalaReducer,
  motoristaReducer,
  bairroReducer,
  gerenciarEntregasReducer,
  veiculoReducer,
  planningReducer,
  loadingRoterizationReducer,
  depositoReducer,
  veiculoTipos,
  romaneioReducer,
  tipoOcorrenciaReducer,
  tipoDespesaReducer,
  motivoPausaReducer,
  motivoNaoEntregaReducer,
  areaEntregaReducer,
  listaRomaneiosReducer,
  PreviewTextReducer,
});

export default rootReducer;
