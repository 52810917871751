import { createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const DefaultTheme = createTheme(
  {
    palette: {
      primary: {
        main: "#f7f7f7",
        text: "#292b2c",
      },
      secondary: {
        main: "#f0ad4e",
        hover: "#ff9800",
      },
      error: {
        main: "#d9534f ",
      },
      success: {
        main: "#5cb85c",
      },
      info: {
        main: "#5bc0de",
      },
      white: {
        main: "#fff",
      },
      gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
      },
    },
    props: {
      MuiButton: {
        variant: "outlined",
        size: "medium",
      },
      MuiTextField: {
        color: "secondary",
        variant: "outlined",
        size: "small",
        fullWidth: true,
        autoComplete: "off",
      },
      MuiSelect: {
        color: "secondary",
        variant: "outlined",
        size: "small",
        fullWidth: true,
        autoComplete: "off",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: "Raleway, Arial",
    },
  },
  ptBR
);
export default DefaultTheme;
