import { actionTypes } from "../Actions/veiculo.action";

const initialState = {
  vehicles: {
    data: [],
  },
  vehicle: {},
  selectedVehicles: {
    data: [],
  },
  success: false,
  error: {},
};

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: payLoad,
        },
      };
    case actionTypes.SELECTED:
      return {
        ...state,
        selectedVehicles: {
          ...state.selectedVehicles,
          data: payLoad,
        },
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: state.vehicles.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
