import { actionTypes } from "../Actions/motivoNaoEntrega.action";

const initialState = {
  motivoNaoEntregas: {
    data: [],
  },
//   vehicle: {},
//   selectedVehicles: {
//     data: [],
//   },
  success: false,
  error: {},
};

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {
        ...state,
        motivoNaoEntregas: {
          ...state.motivoNaoEntregas,
          data: payLoad,
        },
      };
    // case actionTypes.SELECTED:
    //   return {
    //     ...state,
    //     selectedVehicles: {
    //       ...state.selectedVehicles,
    //       data: payLoad,
    //     },
    //   };
    case actionTypes.DESTROY:
      return {
        ...state,
        motivoNaoEntregas: {
          ...state.motivoNaoEntregas,
          data: state.motivoNaoEntregas.data.filter((item) => item.id !== payLoad),
        },
      };
    // case actionTypes.CHANGE:
    //   return {
    //     ...state,
    //     vehicle: {
    //       ...state.vehicle,
    //       ...payLoad,
    //     },
    //   };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
