import { actionTypes } from "../Actions/veiculoTipos.action";

const initialState = {
  vehicleTypes: {
    data: [],
  },
  vehicleType: {},
  success: false,
  error: {},
};

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {    ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          data: payLoad,
        },};
    case actionTypes.DESTROY:
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          data: state.vehicleTypes.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        vehicleType: {
          ...state.vehicleType,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
