import { actionTypes } from "../Actions/loadingRoterization.action";

const INITIAL_STATE = {
  open: false,
  msg: "Roterizando...",
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payLoad }) => {
  switch (type) {
    case actionTypes.CHAGE:
      return { ...state, ...payLoad };
    default:
      return state;
  }
};
