import Axios from "axios";
import { rootUrl, apiAcount, apiCore } from "./app";
import Encrypt, { Decrypt } from "../utils/Encript";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const tk = Decrypt(cookies.get("access_token"));

export const httpAcounts = Axios.create({
  baseURL: apiAcount,
});

export const http = Axios.create({
  baseURL: rootUrl,
});

export const httpCore = Axios.create({
  baseURL: apiCore,
});

export const HttpUpload = Axios.create({
  baseURL: "https://homolog.api.omnicore.vinhasoft.com.br/",
});

httpCore.defaults.headers.authorization = `Bearer ${tk}`;
HttpUpload.defaults.headers.authorization = `Bearer ${tk}`;
http.defaults.headers.authorization = `Bearer ${tk}`;

const refreshAuthToken = async (error) => {
  try {
    // Send a request to your server to refresh the token
    const { data } = await http.post(apiAcount + "/Login/refresh", {
      refreshToken: cookies.get("refresh_token"),
    });
    const {
      token,
      username,
      refreshToken,
      expirationDate,
      serverDateTime,
      serverUTCOffset,
    } = data;
    const tokenEncrypt = Encrypt(token);
    cookies.set("userName", username, { path: "/" });
    cookies.set("access_token", tokenEncrypt, { path: "/" });
    cookies.set("refresh_token", refreshToken, { path: "/" });
    cookies.set("expiration_date", expirationDate, { path: "/" });
    cookies.set("techUser", true, { path: "/" });
    cookies.set("serverDateTime", serverDateTime, { path: "/" });
    cookies.set("serverUTCOffset", serverUTCOffset, { path: "/" });

    delete error.config.headers.authorization;
    delete http.defaults.headers.authorization;
    delete httpAcounts.defaults.headers.authorization;
    delete httpCore.defaults.headers.authorization;

    error.config.headers.authorization = `Bearer ${token}`;
    http.defaults.headers.authorization = `Bearer ${token}`;
    httpCore.defaults.headers.authorization = `Bearer ${token}`;
    httpAcounts.defaults.headers.authorization = `Bearer ${token}`;
    return http.request(error.config);
  } catch (error) {
    // Handle any errors during the token refresh
    console.error("Token refresh failed:", error);
    throw error;
  }
};

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        return await refreshAuthToken(error);
      } catch (e) {
        console.error({ e })
        cookies.remove("access_token");
        window.location.href = "/login";
        return;
      }
    }

    throw error;
  }
);

httpCore.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        return await refreshAuthToken(error);
      } catch (e) {
        console.error({ e })
        cookies.remove("access_token");
        window.location.href = "/login";
        return;
      }
    }

    throw error;
  }
);

HttpUpload.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      try {
        return await refreshAuthToken(error);
      } catch (e) {
        console.error({ e })
        cookies.remove("access_token");
        window.location.href = "/login";
        return;
      }
    }

    throw error;
  }
);
