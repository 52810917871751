import { http } from "../config/http";
import Cookies from "universal-cookie";
import IsAssigned from "./IsAssigned";
const cookies = new Cookies();

export default function IsAuthenticated() {
  if (!cookies.get("access_token")) {
    return false;
  }
  return http
    .get("v1/TokenUtil/Empresas")
    .then((response) => {
      if (response.status === 200) {
        return true;
      }
    })
    .catch((error) => {
      if (error) {
        localStorage.clear();
      }
      if (!cookies.get("access_token")) {
        return false;
      }
    });
}

export function IsTechUser() {
  if (!IsAssigned(cookies.get("techUser"))) {
    return false;
  } else {
    if (cookies.get("techUser") === "true") {
      return true;
    } else {
      return false;
    }
  }
}
