import { httpCore } from "../../config/http";
import { versionEscalaMotorista } from "../../config/Versions";
import { escalaMotorista } from "../../config/Paths";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";
import getErrorMessage from "../../Services/Errors/ErrorsServices";
import { IsTechUser } from "../../Services/Auth";

export const actionsTypes = {
  INDEX: "MOTORISTA_ESCALA_INDEX",
  SHOW: "MOTORISTA_ESCALA_SHOW",
  DESTROY: "MOTORISTA_ESCALA_DESTROY",
  CHANGE: "MOTORISTA_ESCALA_CHANGE",
  SUCCESS: "MOTORISTA_ESCALA_SUCCESS",
  ERROR: "MOTORISTA_ESCALA_ERROR",
};

//INDEX
export const index = (payLoad) => ({
  type: actionsTypes.INDEX,
  payLoad,
});

//DESTROY
export const destroy = (payLoad) => ({
  type: actionsTypes.DESTROY,
  payLoad,
});

//GET_ESCALAS
export const getEscalas = () => (dispatch) => {
  return httpCore
    .get(versionEscalaMotorista + escalaMotorista)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200) {
          dispatch(index(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
      }
      console.log(error.config);
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Ocorreu um erro ao carregar lista de escalas. Tente recarregar a tela.",
        })
      );
    });
};

//STORE_ESCALA
export const store = (escala) => (dispatch) => {
  return httpCore
    .post(versionEscalaMotorista + escalaMotorista, {
      descricao: escala["descricao"],
      qtdMinutosDirecaoContinua: escala["qtdMinutosDirecaoContinua"],
      qtdMinutosTempoPausa: escala["qtdMinutosTempoPausa"],
      qtdMinutosTempoTrabalhoDia: escala["qtdMinutosTempoTrabalhoDia"],
      qtdMinutosTempoDescansoDia: escala["qtdMinutosTempoDescansoDia"],
      horaInicialExpediente: escala["horaInicialExpediente"],
      horaFinalExpediente: escala["horaFinalExpediente"],
    })
    .then((res) => {
      if (typeof res !== "undefined") {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: escala["descricao"] + " cadastrado com sucesso",
            })
          );
          dispatch(getEscalas());
        } else {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar tipo de Carroceria",
            })
          );
        }
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar escala. Tente refazer a operação.",
        })
      );
    });
};

//UPDATE
export const updateEscala = (escala) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Alterando...",
    })
  );
  return httpCore
    .put(versionEscalaMotorista + escalaMotorista + escala.id, escala)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Escala " + (String(escala.descricao).indexOf('\b') !== -1 ? String(escala.descricao).split('\b')[0] : String(escala.descricao)) + " alterada com sucesso",
            })
          );
        }
        dispatch(getEscalas());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar escala. Tente refazer a operação.",
        })
      );
    });
};

//DELETE_ESCALA
export const deleteEscala = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return httpCore
    .delete(versionEscalaMotorista + escalaMotorista + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroy(id));
        }
        dispatch(getEscalas());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 500) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro de Servidor",
            })
          );
        }
        if (error.response.status === 409) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: getErrorMessage(
                error.response.data.errorInfo.errorCode,
                error.response.data.errorInfo.detail,
                "/motoristaEscala",
                IsTechUser()
              ),
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(getEscalas());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        // console.log("Error", error.message);
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao remover a escala. Tente refazer a operação.",
          })
        );
      }
      // console.log(error.config);
    });
};
