import { actionsTypes } from "../Actions/motoristaEscala.action";

const INITIAL_STATE = {
  escalas: {
    data: [],
  },
  escala: {},
  success: false,
  erro: {},
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payLoad }) => {
  switch (type) {
    case actionsTypes.INDEX:
      return {
        ...state,
        escalas: {
          ...state.escalas,
          data: payLoad,
        },
      };
    case actionsTypes.DESTROY:
      return {
        ...state,
        escalas: {
          ...state.escalas,
          data: state.escalas.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionsTypes.CHANGE:
      return {
        ...state,
        escalas: {
          ...state.escalas,
          ...payLoad,
        },
      };
    case actionsTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionsTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
