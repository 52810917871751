import React, { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import IsAuthenticated from "./Services/Auth";

const LoginPage = lazy(() => import("./Pages/Auth"));
const HomePage = lazy(() => import("./Pages/Home"));
const MotoristaEscalaPage = lazy(() => import("./Pages/MotoristaEscala"));
const MotoristaPage = lazy(() => import("./Pages/Motorista"));
const TestPage = lazy(() => import("./Pages/Test"));
const BairroPage = lazy(() => import("./Pages/Bairros"));
const GerenciarEntregasPage = lazy(() => import("./Pages/GerenciarEntregas"));
const GerenciarRoterizacaoPage = lazy(() => import("./Pages/GerenciarRoterizacao"));
const PlanejamentosPage = lazy(() => import("./Pages/Planejamentos"));
const DepositosPage = lazy(() => import("./Pages/Deposito"));
const VeiculoTiposPage = lazy(() => import("./Pages/VeiculoTipos"));
const VeiculoPage = lazy(() => import("./Pages/Veiculo"));
const ListaRomaneiosPage = lazy(() => import("./Pages/ListaRomaneio"));
const TipoOcorrenciaPage = lazy(() => import("./Pages/TipoOcorrencia"));
const TipoDespesaPage = lazy(() => import("./Pages/TipoDespesa"));
const MotivoPausaPage = lazy(() => import("./Pages/MotivoPausa"));
const MotivoNaoEntregaPage = lazy(() => import("./Pages/MotivoNaoEntrega"));
const ConfiguracoesPage = lazy(() => import("./Pages/Configuracoes"));
const AreaEntregaPage = lazy(() => import("./Pages/AreaEntrega"));
const RomaneioPage = lazy(() => import("./Pages/Romaneio"));
const CustomersPage = lazy(() => import("./Pages/Clientes"));
const CustomerDetailPage = lazy(() => import("./Pages/Clientes/details"));
const ContactsPage = lazy(() => import("./Pages/Contatos"));
const ContactDetailPage = lazy(() => import("./Pages/Contatos/details"));
const AddressesPage = lazy(() => import("./Pages/Enderecos"));
const AddressDetailPage = lazy(() => import("./Pages/Enderecos/details"));
const TesteMapaPage = lazy(() => import("./Pages/TesteMapa"));

const PrivateRoute = ({ children, redirectTo }) => {
  return IsAuthenticated() ? children : <Navigate to={redirectTo} />;
};

export const breadcrumbRoutes = [
  // { path: "/", breadcrumb: "VSI-Rota", element: <LoginPage /> },
  { path: "/login", breadcrumb: "Login", element: <LoginPage /> },
  { path: "/home", breadcrumb: "Home", element: <HomePage /> },
  {
    path: "/motoristaEscala",
    breadcrumb: "Escala de Trabalho",
    element: <MotoristaEscalaPage />,
  },
  { path: "/motorista", breadcrumb: "Motoristas", element: <MotoristaPage /> },
  { path: "/bairros", breadcrumb: "Bairros", element: <BairroPage /> },
  {
    path: "/gerenciarEntregas",
    breadcrumb: "Gerenciar Entregas",
    element: <GerenciarEntregasPage />,
  },
  {
    path: "/gerenciarRoterizacao",
    breadcrumb: "Gerenciar Roterização",
    element: <GerenciarRoterizacaoPage />,
  },
  {
    path: "/planejamentos",
    breadcrumb: "Planejamentos",
    element: <PlanejamentosPage />,
  },
  {
    path: "/depositos",
    breadcrumb: "Depósitos",
    element: <DepositosPage />,
  },
  {
    path: "/veiculoTipos",
    breadcrumb: "Tipos de Veículos",
    element: <VeiculoTiposPage />,
  },
  {
    path: "/veiculo",
    breadcrumb: "Veículos",
    element: <VeiculoPage />,
  },
  // {
  //   path: "/romaneios",
  //   breadcrumb: "Lista de Romaneios",
  //   element: <ListaRomaneiosPage />,
  // },
  {
    path: "/tipoOcorrencia",
    breadcrumb: "Tipo de Ocorrencias",
    element: <TipoOcorrenciaPage />,
  },
  {
    path: "/tipoDespesa",
    breadcrumb: "Tipo de Despesa",
    element: <TipoDespesaPage />,
  },
  {
    path: "/motivoPausa",
    breadcrumb: "Motivo de Pausas",
    element: <MotivoPausaPage />,
  },
  {
    path: "/motivoNaoEntrega",
    breadcrumb: "Motivo de Não Entrega",
    element: <MotivoNaoEntregaPage />,
  },
  {
    path: "/areaEntrega",
    breadcrumb: "Área de entrega",
    element: <AreaEntregaPage />,
  },
  {
    path: "/listaRomaneios",
    breadcrumb: "Romaneios",
    element: <RomaneioPage />,
  },

  { path: "/clientes", breadcrumb: 'Clientes', element: <CustomersPage /> },
  { path: "/contatos", breadcrumb: 'Contatos', element: <ContactsPage /> },
  { path: "/listaRomaneios", breadcrumb: 'Lista de romaneios', private: true, element: <RomaneioPage /> },
  
];

const Rotas = () => (
  <Router>
    <Suspense
      fallback={
        <div>
          {" "}
          <CircularProgress color="secondary" />{" "}
        </div>
      }
    >
      <Routes>
        <Route
          path="/home"
          element={
            <PrivateRoute redirectTo={"/"}>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/motoristaEscala"
          element={
            <PrivateRoute redirectTo={"/"}>
              <MotoristaEscalaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/motorista"
          element={
            <PrivateRoute redirectTo={"/"}>
              <MotoristaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/bairros"
          element={
            <PrivateRoute redirectTo={"/"}>
              <BairroPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciarEntregas"
          element={
            <PrivateRoute redirectTo={"/"}>
              <GerenciarEntregasPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciarRoterizacao"
          element={
            <PrivateRoute redirectTo={"/"}>
              <GerenciarRoterizacaoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/planejamentos"
          element={
            <PrivateRoute redirectTo={"/"}>
              <PlanejamentosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/depositos"
          element={
            <PrivateRoute redirectTo={"/"}>
              <DepositosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/veiculoTipos"
          element={
            <PrivateRoute redirectTo={"/"}>
              <VeiculoTiposPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/veiculo"
          element={
            <PrivateRoute redirectTo={"/"}>
              <VeiculoPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/romaneios"
          element={
            <PrivateRoute redirectTo={"/"}>
              <ListaRomaneiosPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tipoOcorrencia"
          element={
            <PrivateRoute redirectTo={"/"}>
              <TipoOcorrenciaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/tipoDespesa"
          element={
            <PrivateRoute redirectTo={"/"}>
              <TipoDespesaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/motivoPausa"
          element={
            <PrivateRoute redirectTo={"/"}>
              <MotivoPausaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/motivoNaoEntrega"
          element={
            <PrivateRoute redirectTo={"/"}>
              <MotivoNaoEntregaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/configuracoes"
          element={
            <PrivateRoute redirectTo={"/"}>
              <ConfiguracoesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/areaentrega"
          element={
            <PrivateRoute redirectTo={"/"}>
              <AreaEntregaPage />
            </PrivateRoute>
          }
        />
        <Route
            path="/listaRomaneios"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <RomaneioPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/clientes"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <CustomersPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/clientes/:id"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <CustomerDetailPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/contatos"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <ContactsPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/contatos/:id"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <ContactDetailPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/enderecos"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <AddressesPage />
                </PrivateRoute>
            }
        />
        <Route
            path="/enderecos/:id"
            element={
                <PrivateRoute redirectTo={"/"}>
                    <AddressDetailPage />
                </PrivateRoute>
            }
        />
        <Route
          path="/testemapa"
          element={
            <PrivateRoute redirectTo={"/"}>
              <TesteMapaPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/teste" element={<TestPage />} />
        {/* <Route path="/romaneios" element={<ListaRomaneiosPage />} /> */}
      </Routes>
    </Suspense>
  </Router>
);

export default Rotas;
