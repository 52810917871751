import { actionTypes } from "../Actions/planning.action";

const initialState = {
  plans: {
    data: [],
  },
  planning: {
    empresaId: 1,
    dataHoraRomaneio: "2021-11-10T14:27:49.823Z",
    regrasLogisticas: [
      {
        nome: "string",
        tempoFixoCarregamentoSegundos: 0,
        tempoFixoDescarregamentoSegundos: 0,
        posicaoRotaCarregamento: "Indiferente",
        posicaoRotaDescarregamento: "Indiferente",
      },
    ],
    depositos: [
      {
        id: 1,
        regraLogistica: "string",
        zonasLogisticas: ["string"],
      },
    ],
    veiculos: [
      // {
      //   id: 1,
      //   motoristaId: 1,
      //   zonaLogistica: "string",
      //   informacoesEntregasPreAlocadas: [0],
      //   dataHoraInicioDisponibilidade: "2021-11-10T14:27:49.823Z",
      //   dataHoraTerminoDisponibilidade: "2021-11-10T14:27:49.823Z",
      // },
      // {
      //   id: 2,
      //   motoristaId: 2,
      //   zonaLogistica: "string",
      //   informacoesEntregasPreAlocadas: [0],
      //   dataHoraInicioDisponibilidade: "2021-11-10T14:27:49.823Z",
      //   dataHoraTerminoDisponibilidade: "2021-11-10T14:27:49.823Z",
      // },
    ],
    informacoesEntregas: [],
  },
  success: false,
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case actionTypes.INDEX:
      return {
        ...state,
        plans: {
          ...state.plans,
          data: payLoad,
        },
      };
    case actionTypes.DESTROY:
      return {
        ...state,
        plans: {
          ...state.plans,
          data: state.plans.data.filter((item) => item.id !== payLoad),
        },
      };
    case actionTypes.CLEAR:
      // const resetedPlanning = {

      // };

      // console.log("resetedPlanning", resetedPlanning);

      return {
        ...state,
        planning: {
          empresaId: 1,
          dataHoraRomaneio: "2021-11-10T14:27:49.823Z",
          regrasLogisticas: [
            {
              nome: "string",
              tempoFixoCarregamentoSegundos: 0,
              tempoFixoDescarregamentoSegundos: 0,
              posicaoRotaCarregamento: "Indiferente",
              posicaoRotaDescarregamento: "Indiferente",
            },
          ],
          depositos: [
            {
              id: 1,
              regraLogistica: "string",
              zonasLogisticas: ["string"],
            },
          ],
          veiculos: [],
          informacoesEntregas: [],
        },
      };
    case actionTypes.CHANGE:
      return {
        ...state,
        planning: {
          ...state.planning,
          ...payLoad,
        },
      };
    case actionTypes.SUCCESS:
      return { ...state, success: payLoad };
    case actionTypes.ERROR:
      return {
        ...state,
        error: payLoad,
      };
    default:
      return state;
  }
};
