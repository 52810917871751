//Entidade
export const entidade = "Entidade";
export const tipoEntidade = "TipoEntidade/";
export const entidadeSegmento = "Segmentos/";
export const motorista = "Funcionario/";
export const escalaMotorista = "EscalaMotorista/";

//Veículo
export const veiculo = "Veiculo/";
export const tipoVeiculo = "TipoVeiculo/";

//Meio Contato
export const contato = "contato/";
export const meioContato = "MeioContato/";
export const endereco = "Endereco/";
export const horarioDisponivelEndereco = "HorarioDisponivelEndereco/";

//PCM
export const Pcm = "Deposito/";

//Tipo Ocorrencia
export const TipoOcorrencia = "TipoOcorrencia/";

//Tipo Despesa
export const TipoDespesa = "TipoDespesa/";

//Motivo Despesa
export const MotivoPausa = "MotivoPausa/";

//Motivo Não Entrega
export const MotivoNaoEntrega = "MotivoNaoEntrega/";

//Status Romaneio
export const RomaneioStatus = "Romaneio/Status/";

//Status Romaneio
export const StetusRomaneioInfo = "StatusRomaneioInfo/";

//CORE
export const Estado = "Estado/";
export const Bairro = "Bairro";
export const Cidade = "Cidade";

//Planejamento
export const Planejamento = "Planejamento/";

//Exportar
export const ExportarRomaneioCSV = "Romaneio/csv/"
export const ExportarRomaneioJSON = "Romaneio/json/"
export const ExportarRomaneioXML = "Romaneio/xml/"
