export const errorInfo = [
  {
    error: "E1005",
    defaultMessageError: "O registro contem dependência com outros registros",
    details: [
      {
        detail: "fkVeiculo",
        defaultMessageDetail: "O Registro contem dependência com um ou mais Veículos",
        pages: [
          {
            page: "/motorista",
            message: "O Motorista está vinculado a um ou mais Veículos",
          },
        ],
      },
      {
        detail: "fkFuncionario",
        defaultMessageDetail: "O Registro contem dependência com um ou mais Motoristas",
        pages: [
          {
            page: "/motoristaEscala",
            message: "A escala está vinculada a um ou mais Motoristas",
          },
        ],
      },
    ],
  },
  {
    error: "E1007",
    details: {
      detail: "Teste",
      defaultMessage: "TESTE",
      pages: [
        {
          page: "motorista",
          message: "TESTE",
        },
      ],
    },
  },
];
