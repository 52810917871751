import {
  Dialog,
  DialogContent,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeLoadingIcon } from "../../store/Actions/loadingicon.action";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.primary,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 2),
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 240,
    margin: "auto",
  },
}));

export default function LoadingRoterization() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loadingiconReducer);
  return (
      <Dialog
        maxWidth="xs"
        className={classes.modal}
        open={loading.open}
        onClose={() => dispatch(changeLoadingIcon({ open: false }))}
      >
        <DialogContent className={classes.paper}>
          <img
            src="/assets/images/logo.svg"
            alt="logo"
            className={classes.logo}
            alignItems="center"
          />
          <br />
          <Typography variant="subtitle1">
            {" "}
            <LinearProgress
              style={{ width: 400, height: 10 }}
              color="secondary"
            />{" "}
            {loading.msg}
          </Typography>
        </DialogContent>
      </Dialog>
  );
}
