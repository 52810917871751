import { http } from "../../config/http";
import { versionMotivoPausa } from "../../config/Versions";
import { MotivoPausa } from "../../config/Paths";
import { changeLoading } from "./loading.action";
import { changeNotify } from "./notify.action";

export const actionTypes = {
  INDEX: "MOTIVO_DESPESA_INDEX",
  SHOW: "MOTIVO_DESPESA_SHOW",
  DESTROY: "MOTIVO_DESPESA_DESTROY",
  CHANGE: "MOTIVO_DESPESA_CHANGE",
  SUCCESS: "MOTIVO_DESPESA_SUCCESS",
  ERROR: "MOTIVO_DESPESA_ERROR",
  SELECTED: "INDEX_SELECTED",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const success = (payLoad) => ({
  type: actionTypes.SUCCESS,
  payLoad,
});

export const error = (payLoad) => ({
  type: actionTypes.ERROR,
  payLoad,
});

//INDEX
export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const indexSelected = (payLoad) => ({
  type: actionTypes.SELECTED,
  payLoad,
});

export const selecteds = (selecteds) => (dispatch) => {
  dispatch(indexSelected(selecteds));
};

export const index = () => (dispatch) => {
  return http
    .get(versionMotivoPausa + MotivoPausa)
    .then(function (res) {
      if (res.response !== "undefined") {
        if (res.request.status === 200) {
          dispatch(indexResponse(res.data.data));
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
      }
      console.log(error.config);
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao carregar motivos de pausa. Tente recarregar a tela.",
        })
      );
    });
};

//STORE
export const store = (motivoPausa) => (dispatch) => {
 
  return http
    .post(versionMotivoPausa + MotivoPausa, {
      descricao: motivoPausa["descricao"],
      ativo: motivoPausa["ativo"],
      exigeObservacao: motivoPausa["exigeObservacao"],
    })
    .then((res) => {
      if (typeof res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
            console.log(res)
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: motivoPausa["descricao"] + " cadastrado com sucesso",
            })
          );
          dispatch(index());
        } else {
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Erro ao cadastrar Motivo de Pausa",
            })
          );
        }
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar o motivo de pausa",
        })
      );
    });
};

//UPDATE
export const update = (data) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
    })
  );
  return http
    .put(versionMotivoPausa + MotivoPausa + data.id, data)
    .then((res) => {
      dispatch(
        changeLoading({
          open: false,
        })
      );
      if (res !== "undefined") {
        if (res.status === 200 || res.status === 201) {
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Alterado com sucesso",
            })
          );
        }
        dispatch(index());
      }
    })
    .catch((erro) => {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao salvar o motivo de pausa",
        })
      );
    });
};

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return http
    .delete(versionMotivoPausa + MotivoPausa + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Excluido com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Motivo de pausa não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());
        console.log(error.request);
      } else {
        // Algo aconteceu na configuração da solicitação que acionou um erro
        console.log("Error", error.message);
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao remover motivo de pausa. Tente refazer a operação.",
          })
        );
      }
      // console.log(error.config);
    });
};
