import { HttpUpload } from "../../config/http";
import { changeLoading } from "../../store/Actions/loading.action";
import { changeNotify } from "../../store/Actions/notify.action";
import Assigned from "../../utils/Assigned";

export const actionTypes = {
  INDEX: "PCM_INDEX",
};

export const change = (payLoad) => ({
  type: actionTypes.CHANGE,
  payLoad,
});

export const indexResponse = (payLoad) => ({
  type: actionTypes.INDEX,
  payLoad,
});

export const index =
  (empresaId = 0) =>
  (dispatch) => {
    let url = "/api/v1/InfoEntrega?DisponivelRoteirizacao=true";

    if (Assigned(empresaId) && empresaId !== 0) {
      url = url + "&empresaId=" + empresaId;
    }

    return HttpUpload.get(url)
      .then((res) => {
        if (res.response !== "undefined") {
          if (res.request.status === 200) {
            dispatch(indexResponse(res.data.data));
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao carregar informações de entregas",
          })
        );
      });
  };

export const upload =
  (csv, sucessHandler = null, badRequestErrorHandler = null) =>
  (dispatch) => {
    // dispatch(
    //     changeLoading({
    //         open: true,
    //         msg: "Carregando Arquivo...",
    //     })
    // );
    return HttpUpload.post("/api/v1/InfoEntrega/UploadCSV", csv, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (
          Assigned(res) &&
          Assigned(res.request) &&
          Assigned(res.request.status)
        ) {
          switch (res.request.status) {
            case 200: {
              dispatch(index());
              sucessHandler(res);
              break;
            }
          }
        }
      })
      .catch((error) => {
        if (
          Assigned(error) &&
          Assigned(error.response) &&
          Assigned(badRequestErrorHandler)
        ) {
          switch (error.response.status) {
            case 400: {
              if (
                Assigned(error.response.data) &&
                error.response.data.length > 0
              ) {
                badRequestErrorHandler(error.response.data);
              }

              break;
            }
          }
        }
      });
  };

//DESTROY
export const destroyResponse = (payLoad) => ({
  type: actionTypes.DESTROY,
  payLoad,
});

export const destroy = (id) => (dispatch) => {
  dispatch(
    changeLoading({
      open: true,
      msg: "Deletando...",
    })
  );
  return HttpUpload.delete("/api/v1/InfoEntrega/" + id)
    .then((res) => {
      if (res !== "undefined") {
        if (res.status === 200) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "success",
              msg: "Entrega excluida com Sucesso",
            })
          );
          dispatch(destroyResponse(id));
        }
        dispatch(index());
      }
    })
    .catch((error) => {
      // Error
      if (error.response) {
        //A solicitação foi feita e o servidor respondeu com um código de status fora do intervalo 2XX
        if (error.response.status === 404) {
          dispatch(
            changeLoading({
              open: false,
            })
          );
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: "Tipo de veículo não encontrado",
            })
          );
        }
      } else if (error.request) {
        //  A solicitação foi feita, mas nenhuma resposta foi recebida
        dispatch(
          changeLoading({
            open: false,
          })
        );
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Sem resposta do servidor",
          })
        );
        dispatch(index());
        console.log(error.request);
      }
      // console.log(error.config);
    });
};
