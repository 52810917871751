import { environment } from "../../config/app";
import { errorInfo } from "./ErrorsList";
import  IsAssigned from "../IsAssigned";

export default function getErrorMessage(errorCode, detail, page = null, IsTechUser) {
  if (!IsAssigned(errorCode) && !IsAssigned(detail)) {
    if (environment === "Deployment") {
      return "Error de passagem parametros (Deployment) ";
    } else {
      return "Contacte o Suporte";
    }
  } else {
    if (errorInfo.filter((erro) => erro.error === errorCode).length === 0) {
      if(IsTechUser){
        return "Erro " +errorCode+ " não encontrado em 'ErrorList'";
      }
      return "Erro ao conectar com o servidor";
    }
    const erroinfo = errorInfo.filter((erro) => erro.error === errorCode)[0];
    let erroinfoDetail = [];
    let erroinfoDetailPage = [];
    if (erroinfo.details.filter((detalhe) => detalhe.detail === detail).length === 0) 
    {
      return erroinfo.defaultMessageError;
    } else {
      erroinfoDetail = erroinfo.details.filter(
        (detalhe) => detalhe.detail === detail
      )[0];
      if (erroinfoDetail.pages.filter((pg) => pg.page === page).length === 0) {
        return erroinfoDetail.defaultMessageDetail;
      } else {
        erroinfoDetailPage = erroinfoDetail.pages.filter(
          (pg) => pg.page === page
        )[0];
      }
    }
      return erroinfoDetailPage.message;   
  }
}