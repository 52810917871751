//Entidades
export const versionEntidade = "v1/";
export const versionTipoEntidade = "v1/";
export const versionSegmentoEntidade = "v1/";
export const versionFuncionario = "v1/";
export const versionEscalaMotorista = "v1/";

//Veículos
export const versionVeiculo = "v1/";
export const versionVeiculoType = "v1/";
export const versionVeiculoBodyWorkType = "v1/";

//PCM
export const versionPcm = "v1/";

//Meio Contato
export const versionMeioContato = "v1/";

//Tipo Ocorrencia
export const versionTipoOcorrencia = "v1/";

//Tipo Despesa
export const versionTipoDespesa = "v1/";

//Motivo Pausa
export const versionMotivoPausa = "v1/";

//Motivo Não Entrega
export const versionMotivoNaoEntrega = "v1/";

//CORE
export const versionEstado = "v1/";
export const versionBairro = "v1/";
export const versionCidade = "v1/";

//Romaneio
export const versionRomaneio = "v1/";

//Status Romaneio
export const versionStatusRomaneioInfo = "v1/";

//Planejamento
export const versionPlanejamento = "v1/";

//Export Romaneio
export const versionRomaneioExport = "v1/"
