import React from "react";
import { Snackbar, Slide, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { changeNotify } from "../../store/Actions/notify.action";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

// eslint-disable-next-line
function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

// eslint-disable-next-line
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

// eslint-disable-next-line
function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

// eslint-disable-next-line
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Notify() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notify = useSelector((state) => state.notifyReducer);

  return (
    <div className={classes.root}>
      <Snackbar
        style={{
          zIndex: 9999999,
        }}
        anchorOrigin={{
          horizontal: notify.horizontal,
          vertical: notify.vertical,
        }}
        open={notify.open}
        autoHideDuration={notify.time}
        onClose={() => dispatch(changeNotify({ open: false }))}
        TransitionComponent={TransitionDown}
      >
        <Alert
          severity={notify.class}
          onClose={() => dispatch(changeNotify({ open: false }))}
          variant="filled"
          elevation={6}
        >
          <Typography>{notify.msg}</Typography>
          {notify.errorDetail.errorInfo !== undefined && (
            <>
              <Typography>
                Codigo do Erro : {notify.errorDetail.errorInfo.errorCode}
              </Typography>
              <Typography>
                Detalhe : {notify.errorDetail.errorInfo.detail}
              </Typography>
              <Typography>Mensagem : {notify.errorDetail.message}</Typography>
            </>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}
